export function validateMail(valor, msg){
    if (!msg) msg ="La dirección de email no es válida."
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return { valid : re.test(String(valor).toLowerCase()), text:msg};
}


export function noVacio(valor, msg){
    if (!msg) msg ="Este valor es requerido."
    return {
        valid : valor && valor!='',
        text: msg
    }
}

export default {validateMail,noVacio}