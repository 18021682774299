import WebFont from 'webfontloader';
import jquery from "jquery";
//import Vector2 from './vector2'
import { calcSnowParams } from "./helpersnow";
import { jsCanvasSnow } from "./jscanvasnow";
import { validateMail,noVacio } from "./validators";
export default (window.$ = window.jQuery = jquery);

WebFont.load({
  google: {
    families: ['Fira Sans Condensed', 'cursive']
  }
});
var contactOn = false;
$(document).ready(function(){

  $('#name').attr('style','');
  $('#mail').attr('style','');
  $('.owl-carousel').owlCarousel({
    margin:10,
    nav:false,
    dots: true,
    video:false,
    lazyLoad:true,
    items:1  
  });
  $(".js-bgfooter-02").on("click",function(){
    if(contactOn) {
      $( ".js-bgfooter-01" ).toggleClass( "blur" );
      $( ".js-bgfooter-02" ).toggleClass( "blur" );
      $( ".js-title-footer" ).toggleClass( "form-contact-close" );
      $( ".js-contact" ).toggleClass( "opacity-0" );
      $( ".js-form-contact" ).toggleClass( "form-contact-open" );
      setTimeout(function(){
        $( ".js-form-contact" ).toggleClass( "flex" );
      },300)
      contactOn = false;
    }
  });
  $(".js-contact").on("click",function(e) {
    contactOn = true;
    e.stopPropagation();
    $( ".js-bgfooter-01" ).toggleClass( "blur" );
    $( ".js-bgfooter-02" ).toggleClass( "blur" );
    $( ".js-title-footer" ).toggleClass( "form-contact-close" );
    $( ".js-contact" ).toggleClass( "opacity-0" );
    $( ".js-form-contact" ).toggleClass( "flex" );
    setTimeout(function(){
      $( ".js-form-contact" ).toggleClass( "form-contact-open" );
    },400)
  });
  $('input').focus(function(){
    $(this).parents('.form-group').addClass('focused');
  });
  
  $('input').blur(function(){
    var inputValue = $(this).val();
    if ( inputValue == "" ) {
      $(this).removeClass('filled');
      $(this).parents('.form-group').removeClass('focused');  
    } else {
      $(this).addClass('filled');
    }
  }) 

    document.querySelectorAll('a[href^="#"]').forEach(anchor => {
      anchor.addEventListener('click', function (e) {
          e.preventDefault();

          document.querySelector(this.getAttribute('href')).scrollIntoView({
              behavior: 'smooth'
          });
      });
  });


// SNOW CANVAS

window.addEventListener('resize', function()
{
  

  jsCanvasSnow.resize(window.innerWidth, window.innerHeight);
    jsCanvasSnow.initParams(calcSnowParams(), [0.5 , 1.5],[0.1, 1],[10, 100],[25, 50]  );
}, false);

window.addEventListener('load', function()
{
  jsCanvasSnow.initParams(calcSnowParams(), [0.5 , 1.5],[0.1, 1],[10, 100],[25, 50]  );
  jsCanvasSnow.start();
}, false);


$('#contact-form').on('submit', function(e){
  e.preventDefault();
  /// validación
  let okf = true;
  $('.showErrorName').hide();
  $('.showErrorMail').hide();
  $('#name').attr('style','');
  $('#mail').attr('style','');
  if (!noVacio($('#name').val()).valid) {
    $('.showErrorName').show();
    $('#name').attr('style','-webkit-box-shadow: 0 2px 0 0 #f06448;');
      okf  = false;
  }
   
  if (!validateMail($('#mail').val()).valid) {
    $('.showErrorMail').show();
    $('#mail').attr('style','-webkit-box-shadow: 0 2px 0 0 #f06448;');
      okf  = false;
  }

  if(!okf) {
    $('.js-send-form').addClass('shake')
    setTimeout(function(){
      $('.js-send-form').removeClass('shake')
    }, 1000 )
    return false;
  }

  // enviar correo

  $('.js-send-form').addClass('spinner');

  var settings = {
    "async": true,
    "crossDomain": true,
    "url": "https://hmmrpmx09j.execute-api.us-east-1.amazonaws.com/default/sendMail",
    "method": "POST",
    "headers": {
      "Content-Type": "application/json",
      "cache-control": "no-cache",
      "Postman-Token": "f91959fd-ad23-40aa-a776-e68b71af899e"
    },
    "processData": false,
    "data": "{\"correoDestino\":[\"info@tech4snow.com\"],\n\"correoFuente\":\"sergio@simbiosys.es\",\n\"asunto\":\"tech4snow - contact with us send by "+$('#name').val()+" Correo: "+$('#mail').val()+"\",\n\"contenido\":\""+$('#text').val()+"\"}"
   }
 $.ajax(settings).done(function (response) {
      //lanzar fb ok

      console.log(response);
      $('.js-send-form').removeClass('spinner');
      $(".js-enviocorrecto").css("display","flex");
      $('.js-enviocorrecto').css("opacity",1);
   }).fail(
     function(error){
//lanzar fb ko
       console.log(error);
       $('.js-send-form').removeClass('spinner');
      $(".js-enviocorrecto").css("display","flex");
      $('.js-enviocorrecto').css("opacity",1);
     }
   );
})

$('.js-cerrar-modal').on("click",function(){
  $(".js-enviocorrecto").css("display","none");
  $('.js-enviocorrecto').css("opacity",0);
  $(".js-enviofallido").css("display","none");
  $('.js-enviofallido').css("opacity",0);
})



});







